import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NewsState } from './news.models';

export const news = createFeatureSelector<NewsState>('news');

export const dashboardNews = createSelector(news, (state) => {
  return state.dashboardNews;
});

export const allNewsPage = createSelector(news, (state) => {
  return state.page;
});

export const allNewsPageSize = createSelector(news, (state) => {
  return state.pageSize;
});

export const allNewsTotal = createSelector(news, (state) => {
  return state.total;
});

export const allNews = createSelector(news, (state) => {
  return state.allNews;
});

export const newsFilter = createSelector(news, (state) => {
  return {
    from: state.from,
    to: state.to,
  };
});

export const newsFilterWithPageAndPageSIZE = createSelector(news, allNewsPageSize, allNewsPage, (state, pageSize, page) => {
  return {
    from: state.from,
    to: state.to,
    pageSize: pageSize,
    page: page,
  };
});

export const detailNews = () =>
  createSelector(news, (state) => {
    const data = {
      loading: state.detail.loading,
      error: state.detail.error,
      data:
        !state.detail.loading && state.detail.data
          ? {
              ...state.detail.data,
            }
          : null,
    };
    return data;
  });

import { createAction, props } from '@ngrx/store';
import { News } from './news.models';

export const setNewsPage = createAction('[News] Setp page', props<{ page: number }>());

export const setNewsPageSize = createAction('[News] Setp page size', props<{ pageSize: number }>());

export const setNewsFilter = createAction('[News] Set filter', props<{ from?: Date; to?: Date }>());

export const loadDashbaordNews = createAction('[News] Load dashboard news');

export const dashboardNewsLoaded = createAction('[News] Dashboard news loaded', props<{ news: News[] }>());

export const loadAllNews = createAction('[News] Load All news');

export const allNewsLoaded = createAction('[News] All news loaded', props<{ news: News[]; total: number }>());

export const loadNewsDetail = createAction('[News] Load News detail', props<{ id: string }>());

export const newsDetailLoaded = createAction('[News] News detail loaded', props<{ news: News }>());
